import { Container, Button, Table, Spinner } from "react-bootstrap";
import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { fetchUsers, deleteUser, updateUser } from "../../store/actions/usersActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import UserSummary from "./UserSummary";
import { AppDispatch } from "../../store";

interface AppState {
  users: any;
}

interface StateProps {
  users: any;
}

interface DispatchProps {
  fetchUsers: (search: string, status: string) => void;
  deleteUser: (user: any) => void;
  updateUser: (user: any) => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const UsersPage: React.FC<Props> = ({ users, fetchUsers, deleteUser, updateUser }) => {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('All');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers(search, status);
  }, [search, status, fetchUsers]);

  const exportData = useCallback(() => {
    setLoading(true);
    const csv = [
      ['First Name', 'Last Name', 'Email', 'Reporting Manager', 'Start Date', 'Progress', 'Status'],
      ...Object.keys(users?.users || {}).map(uid => {
        const user = users.users[uid];
        return [
          user.firstName,
          user.lastName,
          user.email,
          user.reportingManager,
          user.startDate,
          user.status?.currentModule || 0,
          user.status ? (user.status.completed ? 'PASS' : user.status.repeat ? 'FAIL' : 'IN PROGRESS') : 'NOT STARTED'
        ].join(',');
      })
    ];
    const csvContent = "data:text/csv;charset=utf-8," + csv.join("\r\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "users.csv");
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  }, [users]);

  return (
    <Container>
      <Row>
        <Col className="m-1 d-flex">
          <h2> USERS ({Object.keys(users?.users || {}).length || 0}) </h2>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <NavLink to="#" className="m-1" onClick={exportData}>
            <Button variant="primary">
              {loading ? <Spinner animation="border" size='sm' role="status" /> : 'Export'}
            </Button>
          </NavLink>
          <NavLink className="m-1" to='/user/create'>
            <Button variant="primary">Add User</Button>
          </NavLink>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <Form.Group as={Row} className="m-1" controlId="status">
            <Col>
              <Form.Control as="select" onChange={(e) => setStatus(e.target.value)} value={status}>
                <option value='ALL'>All</option>
                <option value='NOT STARTED'>Not Started</option>
                <option value='IN PROGRESS'>In Progress</option>
                <option value='PASS'>Pass</option>
                <option value='FAIL'>Fail</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="m-1" controlId="search">
            <Col>
              <Form.Control placeholder="search" onChange={(e) => setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Table bordered responsive id="UsersTable">
        <thead>
          <tr className="align-middle table-primary">
            <th className="text-center">User Email</th>
            <th className="text-center">First Name</th>
            <th className="text-center">Last Name</th>
            <th className="text-center">Reporting Manager</th>
            <th className="text-center">Start Date</th>
            <th className="text-center">Progress</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(users?.users || {}).length === 0 ? (
            <tr><td colSpan={7} className="text-center">No Records Available</td></tr>
          ) : (
            Object.keys(users?.users || {}).map(uid => (
              <UserSummary key={uid} user={users.users[uid]} deleteUser={deleteUser} updateUser={updateUser} />
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
}

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({ search, status })),
  deleteUser: (user: any) => dispatch(deleteUser(user)),
  updateUser: (user: any) => dispatch(updateUser(user)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(UsersPage);