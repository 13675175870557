import React, { useEffect, useState, useCallback, useMemo } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { GiCyberEye } from "react-icons/gi";
import CardIcon from './CardIcon';
import { NavLink } from "react-router-dom";
import { getCourses } from "../../store/actions/courseActions";
import { AppDispatch } from "../../store";

interface Course {
  id: string;
  name: string;
}

interface AppState {
  courses: {
    list: Record<string, Course>;
  };
}

interface StateProps {
  courses: {
    list: Record<string, Course>;
  };
}

interface DispatchProps {
  getCourses: (search: string) => void;
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const CoursePage: React.FC<Props> = ({ getCourses, courses }) => {
  const [search, setSearch] = useState('');

  const handleGetCourses = useCallback(() => {
    getCourses(search);
  }, [getCourses, search]);

  useEffect(() => {
    handleGetCourses();
  }, [handleGetCourses]);

  const courseList = useMemo(() => courses.list || {}, [courses.list]);

  return (
    <Container className="p-3">
      <Row>
        <Col>
          <h2 className="d-none d-md-block"> COURSES ({Object.keys(courseList).length || 0}) </h2>
        </Col>
        <Col xs='auto' className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" to='/courses/create'>
            <Button variant="primary">
              Add Course 
            </Button>
          </NavLink>
          <Form.Group as={Row} className="m-1" controlId="firstName">
            <Col>
              <Form.Control placeholder="search" onChange={(e) => setSearch(e.target.value)} value={search} />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {Object.keys(courseList).map((course: string, i: number) => (
          <CardIcon to={`/course/${courseList[course].id}`} title={courseList[course].name} key={i}>
            <GiCyberEye />
          </CardIcon>
        ))}
      </Row>
    </Container>
  );
}

const mapStateToProps = (state: AppState) => ({
  courses: state.courses,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCourses: (search: string) => dispatch(getCourses(search)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(CoursePage);