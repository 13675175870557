import React from "react";
import DeleteGroup from './DeleteGroup';

interface Group {
  name: string;
  description: string;
  id: string;
}

interface OwnProps {
  group: Group;
  deleteGroup: (group: Group) => void;
}

type Props = OwnProps;

const GroupSummary: React.FC<Props> = ({ group, deleteGroup }) => {
  return (
    <tr className="align-middle" >
      <td>{group.name}</td>
      <td>{group.description}</td>
      <td>
        <DeleteGroup group={group} deleteGroup={deleteGroup} />
      </td>
    </tr>
  );
};

export default React.memo(GroupSummary);