import { useEffect, useState, useRef, useCallback } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { getVideoURL } from "../../store/actions/courseActions";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";

interface AppState {
  auth: any;
  courses: any;
  users: any;
}

interface StateProps {
  auth: any;
  courses: any;
  users: any;
}

interface OwnProps {
  increaseCount: any;
  count: number;
}

interface DispatchProps {
  getVideoURL: (video: string) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const VideoPage: React.FC<Props> = ({ courses, count, getVideoURL, increaseCount }) => {
  const { id = '' } = useParams<{ id: string }>();
  const videoRef = useRef<HTMLVideoElement>(null);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const [lastTime, setLastTime] = useState(0);
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState({ webm: '', mp4: '' });
  const [title, setTitle] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (courses?.list?.[id]?.modules) {
      setLoading(true);
      const module = courses.list[id].modules[count];
      getVideoURL(module.file);
      setTitle(module.title);
    }
  }, [count, courses?.list?.[id]?.modules, getVideoURL, id]);

  useEffect(() => {
    if (courses.url) {
      setUrl(courses.url);
      setLastTime(0);
      setProgress(0);
      setLoading(false);
    }
  }, [courses.url]);

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      if (videoRef.current.currentTime - lastTime > 1.5) {
        videoRef.current.currentTime = lastTime;
      } else {
        setLastTime(videoRef.current.currentTime ?? 0);
        setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
      }
    }
  }, [lastTime]);

  if (!loading) {
    return (
      <Container style={{ textAlign: 'center' }}>
        <Row className="text-center">
          <Col className="text-center">
            <h3>{title}</h3>
          </Col>
        </Row>
        <Col>
          <video
            autoPlay
            muted={false}
            key={url.webm}
            height="100%"
            width="100%"
            controls
            controlsList="nodownload"
            ref={videoRef}
            onTimeUpdate={handleTimeUpdate}
          >
            {isSafari ? <source src={url.mp4} type="video/mp4" /> : <source src={url.webm} type="video/webm" />}
            Your browser does not support the video tag.
          </video>
          <Row className="justify-content-center">
            {count < 11 && progress > 99.8 && (
              <Col xs="auto">
                <Button className="m-1" variant="success" onClick={increaseCount}>
                  Finish And Next
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Container>
    );
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: '100%' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
};

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getVideoURL: (video: string) => dispatch(getVideoURL(video)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(VideoPage);