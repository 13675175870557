import React, { Key, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createUser, batchCreateUsers, clearUserMessage, fetchUsers } from '../../store/actions/usersActions';
import { Container, Form, Row, Col, Button, Modal, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { fetchGroups, fetchGroup } from '../../store/actions/groupsActions';
import { AppDispatch } from '../../store';

interface AppState {
  users: any;
  auth: any;
  groups: any;
}

interface StateProps {
  users: any;
  auth: any;
  groups: any;
}

interface DispatchProps {
  createUser: (user: any) => void;
  batchCreateUsers: (importUsers: any, gid: string) => void;
  clearMessage: () => void;
  fetchUsers: (search: string, status: string) => void;
  fetchGroups: () => void;
  fetchGroup: (id: string) => void;
}

type Props = StateProps & DispatchProps;

const CreateUser: React.FC<Props> = ({ users, fetchUsers, auth, fetchGroups, fetchGroup, clearMessage, createUser, batchCreateUsers, groups }) => {
  const [disabled, setDisabled] = useState(false);
  const { uid } = auth.user;
  const [importDisabled, setImportDisabled] = useState(false);
  const [importUsers, setImportUsers] = useState<any[]>([]);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [duplicateUser, setDuplicateUser] = useState<any>({});
  const [importGroup, setImportGroup] = useState(users?.users?.[uid]?.group || '');
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    group: users?.users?.[uid]?.group || ''
  });

  useEffect(() => {
    fetchUsers('', 'ALL');
    if (auth.category === "SUPER_ADMIN") fetchGroups();
  }, []);

  useEffect(() => {
    if (auth.category === "ADMIN" && users?.users?.[uid]?.group) fetchGroup(users.users[uid].group);
    setUser((prevUser) => ({
      ...prevUser,
      group: users?.users?.[uid]?.group || ''
    }));
  }, [users]);

  useEffect(() => {
    if (users.message === 'success') {
      setDisabled(false);
      setImportDisabled(false);
      clearMessage();
    }
  }, [users.message]);

  useEffect(() => {
    if (uploadFile) {
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(uploadFile);
    }
  }, [uploadFile]);

  useEffect(() => {
    const existingUser = Object.values(users?.users || {}).find((u: any) => u.email === user.email.trim());
    if (existingUser) {
      setMessage('User Already Exist');
      setDuplicateUser(existingUser);
    } else {
      setMessage('');
      setDuplicateUser({});
    }
  }, [user.email]);

  const csvFileToArray = (string: string) => {
    const csvHeader = ["Email", "FirstName", "LastName"];
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    const array = csvRows.map(row => {
      const values = row.split(",");
      return csvHeader.reduce((object: any, header: string, index: number) => {
        object[header] = values[index];
        return object;
      }, {});
    });
    const existingEmails = Object.values(users?.users || {}).map((u: any) => u.email);
    const uniqueEmails = array.filter((item, index, self) =>
      index === self.findIndex((t: any) => t.Email.trim() === item.Email.trim())
    );
    setImportUsers(uniqueEmails.filter((r: any) => r.Email.trim().length > 0 && !existingEmails.includes(r.Email.trim())));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [id]: value
    }));
  };

  const groupChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setImportGroup(e.target.value);
    setUser((prevUser) => ({
      ...prevUser,
      group: e.target.value
    }));
  };

  const reset = () => {
    setUser({
      firstName: '',
      lastName: '',
      email: '',
      group: users?.users?.[uid]?.group || ''
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setDisabled(true);
    if (message === '') {
      createUser(user);
      reset();
    } else {
      setShow(true);
    }
  };

  const handleFileAttachment = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) setUploadFile(e.target.files[0]);
  };

  const removeRecord = (item: any) => {
    setImportUsers(importUsers.filter(r => r !== item));
  };

  const handleClose = () => {
    setShow(false);
    reset();
    setDisabled(false);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Add User</h3>
        <Form.Group as={Row} className="mb-3" controlId="firstName">
          <Form.Label className='fw-bold' column sm="2">First Name</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={user.firstName} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="lastName">
          <Form.Label className='fw-bold' column sm="2">Last Name</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={user.lastName} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="email">
          <Form.Label className='fw-bold' column sm="2">Email</Form.Label>
          <Col sm="10">
            <Form.Control required type='email' onChange={handleChange} value={user.email} />
          </Col>
        </Form.Group>
        {groups?.list && (
          <Form.Group as={Row} controlId="group">
            <Form.Label className='fw-bold' column sm="2">Group:</Form.Label>
            <Col sm="10">
              <Form.Select required id="group" onChange={groupChange} value={user.group}>
                <option value=''>Select Group</option>
                {Object.keys(groups.list).map((gId) => (
                  <option key={gId} value={gId}>{groups.list[gId].name}</option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        )}
        <Form.Group>
          <Button disabled={disabled} className='m-1' variant="success" type="submit">
            {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Invite'}
          </Button>
          <Button className='m-1' variant="warning" onClick={reset}>
            Reset
          </Button>
          <Button className='m-1' variant="secondary" onClick={() => navigate('/users')}>
            Cancel
          </Button>
        </Form.Group>
      </Form>
      <h3 className='grey-text text-darken-3'>Import Users</h3>
      <h4 className='grey-text text-darken-3'>Please upload a csv file with "Email, First Name, Last Name" as Column Header</h4>
      {groups?.list && (
        <Form.Group as={Row} controlId="importGroup">
          <Form.Label className='fw-bold' column sm="2">Group:</Form.Label>
          <Col sm="10">
            <Form.Select required id="importGroup" onChange={groupChange} value={importGroup}>
              <option disabled hidden value=''>Select Group</option>
              {Object.keys(groups.list).map((gId) => (
                <option key={gId} value={gId}>{groups.list[gId].name}</option>
              ))}
            </Form.Select>
          </Col>
        </Form.Group>
      )}
      <Form.Group controlId="formFile" className="mb-3">
        <Form.Label>Upload CSV File</Form.Label>
        <Form.Control type="file" accept='.csv' onChange={handleFileAttachment} />
      </Form.Group>
      {importUsers.length > 0 && (
        <>
          <Container className='m-3' style={{ maxHeight: "400px", overflowY: "auto", padding: "15px" }}>
            <Table>
              <thead>
                <tr key={"header"}>
                  <th className="text-center">Email</th>
                  <th className="text-center">First Name</th>
                  <th className="text-center">Last Name</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {importUsers.map((item: any) => (
                  <tr key={item.id} className="align-middle">
                    {Object.values(item).map((val) => (
                      <td key={val as Key}>{val as ReactNode}</td>
                    ))}
                    <td>
                      <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={() => removeRecord(item)}>
                        <i className="bi bi-trash"></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
          <h4>There are {importUsers.length} Unique Email IDs identified in the file uploaded</h4>
          <Button disabled={importDisabled} className='m-3' onClick={() => { setImportDisabled(true); batchCreateUsers(importUsers, importGroup) }}>
            {importDisabled ? <Spinner animation="border" size='sm' role="status" /> : `Invite ${importUsers.length}`}
          </Button>
        </>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {duplicateUser.firstName} {duplicateUser.lastName}</p>
          <p>Email: {duplicateUser.email}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createUser: (user: any) => dispatch(createUser(user)),
  batchCreateUsers: (importUsers: any[], gid: string) => dispatch(batchCreateUsers({ userList: importUsers, groupId: gid })),
  clearMessage: () => dispatch(clearUserMessage()),
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({ search, status })),
  fetchGroups: () => dispatch(fetchGroups()),
  fetchGroup: (id: string) => dispatch(fetchGroup(id))
});

const mapStateToProps = (state: AppState) => state;

export default connect<StateProps, DispatchProps, {}, AppState>(mapStateToProps, mapDispatchToProps)(CreateUser);