import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface User {
  email: string;
  firstName: string;
  lastName: string;
  uid: string;
}

interface OwnProps {
  user: User;
  deleteUser: (user: User) => void;
}

type Props = OwnProps;

const DeleteUser: React.FC<Props> = ({ user, deleteUser }) => {
  const [show, setShow] = useState(false);
  const toggleModal = () => setShow(!show);

  return (
    <>
      <span className="btn btn-danger m-1 rounded-circle modal-trigger" onClick={toggleModal}>
        <i className="bi bi-trash"></i>
      </span>
      <Modal show={show} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>ID: {user.email}</p>
          <p>Name: {user.firstName} {user.lastName}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={toggleModal}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { toggleModal(); deleteUser(user); }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteUser;