import React from "react";
import DeleteUser from './DeleteUser';
import { ProgressBar } from "react-bootstrap";
import EditUser from "./EditUser";

interface UserStatus {
  completed?: boolean;
  retake?: boolean;
  score?: { [key: string]: number };
  currentModule: number;
}

interface User {
  email: string;
  firstName: string;
  lastName: string;
  managerEmail: string;
  startDate: string;
  uid: string;
  status?: { [key: string]: UserStatus };
}

interface OwnProps {
  user: User;
  deleteUser: (user: any) => void;
  updateUser: (user: User) => void;
}

type Props = OwnProps;

const UserSummary: React.FC<Props> = ({ user, deleteUser, updateUser }) => {
  const getClassName = () => {
    if (user.status && user.status.cyberSecurity) {
      if (!user.status.cyberSecurity.completed && user.status.cyberSecurity.retake) return 'table-danger';
      if (user.status.cyberSecurity.completed) return 'table-success';
    }
    return '';
  };

  const getProgressBarValue = () => {
    if (user.status && user.status.cyberSecurity) {
      if (user.status.cyberSecurity.score && user.status.cyberSecurity.score['11']) return 100;
      return ((user.status.cyberSecurity.currentModule || 1) - 1) * 100 / 11;
    }
    return 0;
  };

  const getModuleText = () => {
    if (user.status && user.status.cyberSecurity) {
      if (user.status.cyberSecurity.score && user.status.cyberSecurity.score['11']) return '12/12';
      return `${user.status.cyberSecurity.currentModule || 0}/12`;
    }
    return '0/12';
  };

  return (
    <tr className={`align-middle ${getClassName()}`}>
      <td>{user.email}</td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.managerEmail}</td>
      <td>{user.startDate}</td>
      <td>
        {getModuleText()}
        <ProgressBar variant={user.status && user.status.cyberSecurity && user.status.cyberSecurity.completed ? 'success' : 'primary'} now={getProgressBarValue()} />
      </td>
      <td>
        <EditUser user={user} updateUser={updateUser} />
        <DeleteUser user={user} deleteUser={deleteUser} />
      </td>
    </tr>
  );
}

export default UserSummary;