import React, { useState, ChangeEvent, FormEvent } from "react";
import { Button, Form } from "react-bootstrap";

interface QuestionProps {
  question: {
    question: string;
    options: string[];
    answer: string;
  };
  increaseScore: () => void;
  nextQuestion: () => void;
}

const Question: React.FC<QuestionProps> = ({ question, increaseScore, nextQuestion }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedOption === question.answer) {
      increaseScore();
    }
    nextQuestion();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <h4>{question.question}</h4>
      {question.options.map(option => (
        <Form.Check
          key={option}
          type="radio"
          label={option}
          name="question"
          value={option}
          checked={selectedOption === option}
          onChange={handleOptionChange}
          className="custom-radio"
        />
      ))}
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default Question;