import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";

const AdminLinks: React.FC = () => {
  return (
    <>
      <Nav.Link as={Link} to='/'>Dashboard</Nav.Link>
      <Nav.Link as={Link} to='/groups'>Groups</Nav.Link>
      <Nav.Link as={Link} to='/reports'>Reports</Nav.Link>
      <Nav.Link as={Link} to='/users'>Users</Nav.Link>
      <Nav.Link as={Link} to='/courses'>Courses</Nav.Link>
    </>
  );
}

export default AdminLinks;