import { Container, Button, Table, Spinner } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { filterGroups, fetchGroups, deleteGroup } from "../../store/actions/groupsActions";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import GroupSummary from "./GroupSummary";
import { AppDispatch } from "../../store";

interface Group {
  name: string;
  description: string;
  id: string;
}

interface AppState {
  groups: {
    list: Record<string, Group>;
    message?: string;
  };
}

interface AppProps {
  groups: {
    list: Record<string, Group>;
    message?: string;
  };
}

interface OwnProps {}

interface DispatchProps {
  filterGroups: (search: string) => void;
  fetchGroups: () => void;
  deleteGroup: (group: Group) => void;
}

type Props = AppProps & OwnProps & DispatchProps;

const GroupsPage: React.FC<Props> = ({ groups, filterGroups, fetchGroups, deleteGroup }) => {
  const keys = Object.keys(groups?.list || {});
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (search.length) {
      filterGroups(search);
    } else {
      fetchGroups();
    }
  }, [search, filterGroups, fetchGroups]);

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="d-none d-md-block">GROUPS ({keys.length})</h2>
        </Col>
        <Col xs="auto" className="m-1 d-flex flex-row-reverse">
          <NavLink className="m-1" to="/group/create">
            <Button variant="primary">Add Group</Button>
          </NavLink>
          <Form.Group as={Row} className="m-1" controlId="search">
            <Col>
              <Form.Control
                placeholder="search"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Table bordered responsive id="GroupsTable">
        <thead>
          <tr className="align-middle table-primary">
            <th className="text-center">Name</th>
            <th className="text-center">Description</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {keys.length === 0 ? (
            <tr>
              <td colSpan={3}>No Records Available</td>
            </tr>
          ) : (
            keys.map((id) => (
              <GroupSummary key={id} group={groups.list[id]} deleteGroup={deleteGroup} />
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  filterGroups: (search: string) => dispatch(filterGroups(search)),
  fetchGroups: () => dispatch(fetchGroups()),
  deleteGroup: (group: Group) => dispatch(deleteGroup(group)),
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(GroupsPage));