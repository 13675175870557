import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, Form, Button, Spinner, Modal, Table } from 'react-bootstrap';
import { createCourse } from '../../store/actions/courseActions';
import { AppDispatch } from '../../store';

interface Module {
  type: string;
  title: string;
  file: string;
  fileUpload: File;
}

interface Course {
  name: string;
  description: string;
  modules: Module[];
}

interface DispatchProps {
  createCourse: (course: Course) => void;
}

type Props = DispatchProps;

const CreateCourse: React.FC<Props> = ({ createCourse }) => {
  const [course, setCourse] = useState<Course>({ name: '', description: '', modules: [] });
  const [loading, setLoading] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showQuizModal, setShowQuizModal] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setCourse(prevCourse => ({ ...prevCourse, [id]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    createCourse(course);
    setLoading(false);
    setCourse({ name: '', description: '', modules: [] });
  };

  const handleAddVideo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e.target.files![0];
    const file = fileUpload.name.split('.').slice(0, -1).join('.');
    const newModule: Module = { type: 'video', title: '', file, fileUpload };
    setCourse(prevCourse => ({ ...prevCourse, modules: [...prevCourse.modules, newModule] }));
    setShowVideoModal(false);
  };

  const renderVideoModal = () => (
    <Modal show={showVideoModal} onHide={() => setShowVideoModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Video Module</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='fileUpload'>
          <Form.Label>Video File</Form.Label>
          <Form.Control type='file' onChange={handleAddVideo} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowVideoModal(false)}>Close</Button>
        <Button variant='primary'>Add Video</Button>
      </Modal.Footer>
    </Modal>
  );

  const renderQuizModal = () => (
    <Modal show={showQuizModal} onHide={() => setShowQuizModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add Quiz Module</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='title'>
          <Form.Label>Quiz Title</Form.Label>
          <Form.Control type='text' placeholder='Enter quiz title' />
        </Form.Group>
        <Form.Group controlId='questions'>
          <Form.Label>Questions</Form.Label>
          <Button variant='secondary'>Add Question</Button>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={() => setShowQuizModal(false)}>Close</Button>
        <Button variant='primary'>Add Quiz</Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <Container>
      <h2>Create Course</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId='name'>
          <Form.Label>Course Name</Form.Label>
          <Form.Control type='text' value={course.name} placeholder='Enter course name' onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId='description'>
          <Form.Label>Course Description</Form.Label>
          <Form.Control as='textarea' value={course.description} rows={3} placeholder='Enter course description' onChange={handleChange} />
        </Form.Group>
        <Form.Group controlId='modules'>
          <Form.Label>Modules</Form.Label>
          <Button className='m-1' variant='secondary' onClick={() => setShowVideoModal(true)}>Add Video</Button>
          <Button className='m-1' variant='secondary' onClick={() => setShowQuizModal(true)}>Add Quiz</Button>
        </Form.Group>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Title</th>
              <th>File Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {course.modules.map((module, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{module.type}</td>
                <td>
                  <Form.Control type='text' value={module.title} onChange={(e) => {
                    const updatedModules = [...course.modules];
                    updatedModules[index].title = e.target.value;
                    setCourse({ ...course, modules: updatedModules });
                  }} />
                </td>
                <td>
                  <Form.Control type='text' value={module.file} onChange={(e) => {
                    const updatedModules = [...course.modules];
                    updatedModules[index].file = e.target.value;
                    setCourse({ ...course, modules: updatedModules });
                  }} />
                </td>
                <td>
                  <span className='btn btn-danger m-1 rounded-circle modal-trigger'>
                    <i className='bi bi-trash'></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant='primary' type='submit'>
          {loading ? <Spinner animation='border' size='sm' /> : 'Create Course'}
        </Button>
      </Form>
      {renderVideoModal()}
      {renderQuizModal()}
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createCourse: (course: Course) => dispatch(createCourse(course)),
});

export default connect(null, mapDispatchToProps)(CreateCourse);