import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { NavLink, To } from 'react-router-dom';

interface OwnProps {
  to: To;
  children: ReactNode;
  title: string;
}

type Props = OwnProps;

const CardIcon: React.FC<Props> = ({ to, children, title }) => {
  return (
    <Card className='card-icon m-2'>
      <NavLink to={to} className="card-link">
        <center>
          <svg xmlns="http://www.w3.org/2000/svg" className='m-1' width="3.5rem" height="3.5rem" viewBox="0 0 16 16">
            {children}
          </svg>
          <h4 className='card-title'>{title}</h4>
        </center>
      </NavLink>
    </Card>
  );
};

export default CardIcon;