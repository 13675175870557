import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchUsers } from '../../store/actions/usersActions';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Pie, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { AppDispatch } from "../../store";

Chart.register(ArcElement, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface AppState {
  users: any;
}

interface StateProps {
  users: any;
}

interface OwnProps {}

interface DispatchProps {
    fetchUsers: (search: string, status: string) => void;
}

type Props = DispatchProps & StateProps & OwnProps;

const ReportsView: React.FC<Props> = ({users, fetchUsers}) => {
  useEffect(() => {
    fetchUsers('', 'All');
  }, [fetchUsers]);

  const getStatusCounts = () => {
    const counts = { completed: 0, inProgress: 0, notStarted: 0 };
    users && users.users && Object.values(users.users).forEach((user: any) => {
      const { cyberSecurity } = user.status || {};
      if (cyberSecurity?.completed) {
        counts.completed += 1;
      } else if (cyberSecurity?.currentModule > 0) {
        counts.inProgress += 1;
      } else {
        counts.notStarted += 1;
      }
    });
    return counts;
  };

  const getVideoProgressCounts = () => {
    const videoCounts: { [key: number]: number } = {};
    users && users.users && Object.values(users.users).forEach((user: any) => {
      const video = user.status?.cyberSecurity?.currentModule || 0;
      videoCounts[video] = (videoCounts[video] || 0) + 1;
    });
    return videoCounts;
  };

  const statusCounts = getStatusCounts();
  const videoProgressCounts = getVideoProgressCounts();

  const pieData = {
    labels: ['Completed', 'In Progress', 'Not Started'],
    datasets: [{
      data: [statusCounts.completed, statusCounts.inProgress, statusCounts.notStarted],
      backgroundColor: ['#28a745', '#ffc107', '#dc3545'],
    }],
  };

  const barData = {
    labels: Object.keys(videoProgressCounts),
    datasets: [{
      label: 'Users per Video Progress',
      data: Object.values(videoProgressCounts),
      backgroundColor: '#007bff',
    }],
  };

  return (
    <Container>
      <div className="reports-view">
        <h2>Users Report ({Object.keys(users?.users || {}).length || 0} Users)</h2>
        <Row className="chart-container" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
          <Col md={4}>
            <h4>User Status Distribution</h4>
            <Pie data={pieData} />
          </Col>
          <Col md={4}>
            <h4>CyberSecurity Video Progress</h4>
            <Bar data={barData} options={{ scales: { y: { beginAtZero: true } } }} />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchUsers: (search: string, status: string) => dispatch(fetchUsers({ search, status })),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(ReportsView);
