import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset, clearError } from '../../store/actions/authActions';
import { Container, Form, Button, Alert, Spinner, Row, Col, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import bg from '../../assets/bg.jpg';
import logo from "../../assets/Logo.png";
import { AppDispatch, RootState } from '../../store';

const Reset: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const { resetError } = useSelector((state: RootState) => state.auth);
  const [error, setError] = useState(resetError);

  useEffect(() => {
    setError(resetError);
    if (resetError) {
      setDisabled(false);
    }
  }, [resetError]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(clearError());
    dispatch(reset(email));
    setDisabled(false);
    setEmail('');
  };

  return (
    <Row style={{ width: '100%', height: '100%' }}>
      <Col xs={7} className='d-none d-md-block'>
        <Container style={{ width: '100%', height: '100%', padding: 0, margin: 0 }}>
          <Image src={bg} style={{ height: '100%', width: '100%' }} />
        </Container>
      </Col>
      <Col className='d-flex align-items-center justify-content-center vh-100'>
        <Container style={{ textAlign: 'center' }}>
          <Image className='m-3' src={logo} width={100} height={100} />
          <h3 className='grey-text text-darken-3'>Reset Password</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>

            {error && (
              <Form.Group className='mb-3' controlId="errorMessage">
                <Form.Text id='error'>
                  <Alert key='error' variant='danger'>
                    <div className='d-flex justify-content-between'>
                      <span>
                        {error}
                      </span>
                      <span onClick={() => dispatch(clearError())}>
                        X
                      </span>
                    </div>
                  </Alert>
                </Form.Text>
              </Form.Group>
            )}

            <Form.Group>
              <Button disabled={disabled} id="reset" variant="primary" type="submit" className='m-1'>
                {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Reset'}
              </Button>
              <Button variant="primary" onClick={() => navigate('/signin')}>
                Sign In
              </Button>
            </Form.Group>
          </Form>
        </Container>
      </Col>
    </Row>
  );
};

export default Reset;