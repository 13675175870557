import React, { useState, ChangeEvent } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';

interface User {
  firstName: string;
  email: string;
  startDate: string;
  lastName: string;
  managerEmail: string;
  group?: string;
  uid: string;
}

interface OwnProps {
  user: User;
  updateUser: (user: User) => void;
}

interface StateProps {
  groups: any;
}

interface AppState {
  groups: any;
}

interface DispatchProps {}

type Props = DispatchProps & OwnProps & StateProps;

const EditUser: React.FC<Props> = ({ user, groups, updateUser }) => {
  const [show, setShow] = useState(false);
  const [editUser, setEditUser] = useState<User>(user);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setEditUser((prevUser) => ({ ...prevUser, [id]: value }));
  };

  return (
    <>
      <span className="btn btn-primary m-1 rounded-circle modal-trigger" onClick={handleShow}>
        <i className="bi bi-pencil"></i>
      </span>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="firstName">
            <Form.Label>First Name:</Form.Label>
            <Form.Control required type="text" value={editUser.firstName} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="lastName">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control required type="text" value={editUser.lastName} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="managerEmail">
            <Form.Label>Reporting Manager Email:</Form.Label>
            <Form.Control required type="email" value={editUser.managerEmail} onChange={handleChange} />
          </Form.Group>
          {groups.list && (
            <Form.Group controlId="group">
              <Form.Label>Group:</Form.Label>
              <Form.Control as="select" onChange={handleChange} value={editUser.group || ''}>
                <option value="">Select Group</option>
                {Object.keys(groups.list).map((gId: string) => (
                  <option key={gId} value={gId}>
                    {groups.list[gId].name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={() => { updateUser(editUser); handleClose(); }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  groups: state.groups,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps)(EditUser);