import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createGroup, clearMessage, fetchGroups } from '../../store/actions/groupsActions';
import { Container, Form, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';

interface AppState {
  groups: any;
}

interface StateProps {
  groups: any;
}

interface DispatchProps {
  createGroup: (group: Group) => void;
  clearMessage: () => void;
  fetchGroups: () => void;
}

interface Group {
  id: string;
  name: string;
  description: string;
  users?: string[];
}

interface OwnProps {}

type Props = StateProps & OwnProps & DispatchProps;

const CreateGroup: React.FC<Props> = ({ groups, clearMessage, fetchGroups, createGroup }) => {
  
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [duplicateGroup, setDuplicateGroup] = useState<Group>({ name: '', description: '', id: '' });
  const [group, setGroup] = useState<Group>({ name: '', description: '', id: '' });
  
  const handleClose = () => {
    setShow(false);
    reset();
    setDisabled(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    if (groups.list && Object.keys(groups.list).some(id => groups.list[id].name === group.name.trim())) {
      setMessage('Group Already Exist');
      setDuplicateGroup(groups.list[Object.keys(groups.list).find(id => groups.list[id].name === group.name.trim())!]);
    } else {
      setMessage('');
      setDuplicateGroup({ name: '', description: '', id: '' });
    }
  }, [group.name, groups.list]);

  useEffect(() => {
    if (groups?.message === 'Group created successfully') {
      setDisabled(false);
      clearMessage();
    }
  }, [groups, clearMessage]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setGroup(prevGroup => ({ ...prevGroup, [id]: value }));
  };

  const reset = () => {
    setGroup({ name: '', description: '', id: '' });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisabled(true);
    if (message === '') {
      createGroup(group);
      reset();
    } else {
      handleShow();
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <h3 className='grey-text text-darken-3'>Add Group</h3>
        <Form.Group as={Row} className="mb-3" controlId="name">
          <Form.Label className='fw-bold' column sm="2">Name</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={group.name} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="description">
          <Form.Label className='fw-bold' column sm="2">Description</Form.Label>
          <Col sm="10">
            <Form.Control required onChange={handleChange} value={group.description} />
          </Col>
        </Form.Group>
        <Form.Group>
          <Button disabled={disabled} className='m-1' variant="success" type="submit">
            {disabled ? <Spinner animation="border" size='sm' role="status" /> : 'Create'}
          </Button>
          <Button className='m-1' variant="warning" onClick={reset}>
            Reset
          </Button>
          <Button className='m-1' variant="secondary" onClick={() => navigate('/groups')}>
            Cancel
          </Button>
        </Form.Group>
      </Form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Name: {duplicateGroup.name}</p>
          <p>Description: {duplicateGroup.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createGroup: (group: Group) => dispatch(createGroup(group)),
  clearMessage: () => dispatch(clearMessage()),
  fetchGroups: () => dispatch(fetchGroups()),
});

const mapStateToProps = (state: AppState) => ({
  groups: state.groups,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(React.memo(CreateGroup));