import React, { useEffect, useState } from "react";
import { Container, Row, Col, Spinner, Card } from "react-bootstrap";
import CardIcon from './CardIcon';
import { connect } from 'react-redux';
import { FaUser, FaUsers, FaBook, FaChartBar } from 'react-icons/fa'; // Import modern icons
import './Dashboard.css'; // Import custom CSS for additional styling

interface AppState {
  auth: any;
}

interface StateProps {
  auth: any;
}

interface OwnProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const Dashboard: React.FC<Props> = ({ auth }) => {
  const { category } = auth;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (category && category !== 'LOGIN') {
      setLoading(false);
    }
  }, [category]);

  if (loading) {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  return (
    <Container className="dashboard-container p-3">
      <Row className="mb-4">
        <Col>
          <h1 className="dashboard-title">Welcome to the LMS Dashboard</h1>
        </Col>
      </Row>
      <Row>
        {(category === 'ADMIN' || category === 'SUPER_ADMIN') && (
          <>
            <Col md={2}>
              <CardIcon to='/users' title="Users">
                <FaUser />
              </CardIcon>
            </Col>
            <Col md={2}>
              <CardIcon to='/reports' title="Reports">
                <FaChartBar />
              </CardIcon>
            </Col>
          </>
        )}
        <Col md={2}>
          <CardIcon to='/courses' title="Courses">
            <FaBook />
          </CardIcon>
        </Col>
        {category === 'SUPER_ADMIN' && (
          <>
            <Col md={2}>
              <CardIcon to='/groups' title="Groups">
                <FaUsers />
              </CardIcon>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
};

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps)(React.memo(Dashboard));