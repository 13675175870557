import { Container, Spinner, ListGroup, Col, Row, ProgressBar } from "react-bootstrap";
import VideoPage from "./VideoPage";
import QuizPage from "./QuizPage";
import { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { setStatus, getStatus, getCourse } from "../../store/actions/courseActions";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";

interface AuthState {
  user: {
    uid: string;
  };
}

interface CourseState {
  list: {
    [key: string]: {
      modules: {
        [key: number]: {
          type: string;
          title: string;
        };
      };
    };
  };
  status: {
    currentModule: number;
    score: {
      [key: number]: number;
    };
  };
}

interface AppState {
  auth: AuthState;
  courses: CourseState;
}

interface DispatchProps {
  setStatus: (uid: string, id: string, status: any) => void;
  getStatus: (uid: string, id: string) => void;
  getCourse: (id: string) => void;
}

type Props = AppState & DispatchProps;

const CourseContent: React.FC<Props> = ({ auth, courses, getStatus, setStatus, getCourse }) => {
  const { id } = useParams<{ id: string }>();
  const courseId = id ?? '';
  const { uid } = auth.user;
  const [loading, setLoading] = useState(true);
  const [currentModule, setCurrentModule] = useState(-1);

  useEffect(() => {
    getCourse(courseId);
  }, [courseId, getCourse]);

  const increaseModuleCount = useCallback(() => {
    
    if (courses.status.currentModule === currentModule) {
      setStatus(uid, courseId, { ...courses.status, currentModule: currentModule + 1 });
    } else if(courses.status.currentModule === undefined) {
      setStatus(uid, courseId, { currentModule: 1 });
    }
    setCurrentModule(currentModule + 1);
  }, [currentModule, courses.status, courseId, setStatus, uid]);

  const increaseModuleWithScores = useCallback(
    (score: number) => {
      if (courses.status.currentModule === currentModule) {
        setStatus(uid, courseId, {
          ...courses.status,
          currentModule: currentModule + 1,
          score: { ...courses.status.score, [currentModule]: score },
        });
      }
      setCurrentModule(currentModule + 1);
    },
    [currentModule, courses.status, courseId, setStatus, uid]
  );

  useEffect(() => {
    if (currentModule >= 0 && courses.list && courses.list[courseId] && Object.keys(courses.list[courseId].modules).length > 0) {
      setLoading(false);
    }
  }, [currentModule, courses.list, courseId]);

  useEffect(() => {
    if (courses.status) {
      setCurrentModule(courses.status.currentModule ?? 0);
    } else {
      getStatus(uid, courseId);
    }
  }, [courses.status, getStatus, courseId, uid]);

  if (!loading) {
    return (
      <Container>
        <Row>
          <Col className="order-md-2">
            {courses.list[courseId].modules[currentModule].type === "video" ? (
              <VideoPage increaseCount={increaseModuleCount} count={currentModule} />
            ) : (
              <QuizPage increaseCountWithScore={increaseModuleWithScores} count={currentModule} />
            )}
          </Col>
          <Col md="auto" className="order-md-1">
            <ListGroup>
              {Object.keys(courses.list[courseId].modules).map((module, i) => (
                <ListGroup.Item
                  active={i === currentModule}
                  key={i}
                  action
                  disabled={courses.status.currentModule ? i > courses.status.currentModule : i > 0}
                  onClick={() => setCurrentModule(i)}
                >
                  {courses.list[courseId].modules[i].title}
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }
};

const mapStateToProps = (state: AppState) => state;

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setStatus: (uid: string, id: string, status: any) => dispatch(setStatus({ uid, id, status })),
  getStatus: (uid: string, id: string) => dispatch(getStatus({ uid, id })),
  getCourse: (id: string) => dispatch(getCourse(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseContent);