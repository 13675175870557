import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";

const links = [
  { to: '/', label: 'Dashboard' },
  { to: '/users', label: 'Users' },
  { to: '/courses', label: 'Courses' },
  { to: '/reports', label: 'Reports'}
];

const AdminLinks: React.FC = () => {
  return (
    <>
      {links.map((link, index) => (
        <Nav.Link as={Link} to={link.to} key={index}>
          {link.label}
        </Nav.Link>
      ))}
    </>
  );
}

export default AdminLinks;