import { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { connect } from 'react-redux';
import { registerUser, fetchUser } from '../../store/actions/usersActions';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { AppDispatch } from '../../store';

interface AuthState {
    user: {
        uid: string;
        email: string;
    };
}

interface UserState {
    user: {
        group: string;
    };
}

interface AppState {
    auth: AuthState;
    users: UserState;
}

interface AppProps {
    auth: AuthState;
    users: UserState;
}

interface DispatchProps {
    registerUser: (user: User) => void;
    fetchUser: (uid: string) => void;
}

interface OwnProps {}

type Props = AppProps & OwnProps & DispatchProps;

interface User {
    firstName: string;
    lastName: string;
    managerEmail: string;
    uid: string;
    startDate: string;
    email: string;
    group?: string;
}

const RegisterUser: React.FC<Props> = ({ auth, fetchUser, users, registerUser }) => {
    const { uid, email } = auth.user;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [managerEmail, setManagerEmail] = useState('');
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        fetchUser(uid);
    }, [fetchUser, uid]);

    const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLastName(e.target.value);
    };

    const handleManagerEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setManagerEmail(e.target.value);
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        setDisabled(true);
        e.preventDefault();
        const user: User = {
            firstName,
            lastName,
            managerEmail,
            uid,
            startDate: new Date().toLocaleDateString(),
            email,
            group: users.user.group,
        };
        registerUser(user);
    };

    return (
        <Container>
            <h2>User Registration</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="firstName">
                    <Form.Label>First Name:</Form.Label>
                    <Form.Control required type="text" value={firstName} onChange={handleFirstNameChange} />
                </Form.Group>
                <Form.Group controlId="lastName">
                    <Form.Label>Last Name:</Form.Label>
                    <Form.Control required type="text" value={lastName} onChange={handleLastNameChange} />
                </Form.Group>
                <Form.Group controlId="managerEmail">
                    <Form.Label>Reporting Manager Email:</Form.Label>
                    <Form.Control required type="email" value={managerEmail} onChange={handleManagerEmailChange} />
                </Form.Group>
                <Button variant="primary" type="submit" disabled={disabled}>
                    {disabled ? <Spinner animation="border" size="sm" role="status" /> : 'Register'}
                </Button>
            </Form>
        </Container>
    );
};

const mapStateToProps = (state: AppState): AppProps => ({
    auth: state.auth,
    users: state.users,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
    registerUser: (user: User) => dispatch(registerUser(user)),
    fetchUser: (uid: string) => dispatch(fetchUser(uid)),
});

export default connect<AppProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(RegisterUser);