import { ReactNode } from 'react';
import { Card } from 'react-bootstrap';
import { NavLink, To } from 'react-router-dom';

interface OwnProps {
  to: To;
  children: ReactNode;
  title: string;
}

type Props = OwnProps;

const CardIcon: React.FC<Props> = ({ to, children, title }) => {
  return (
    <Card style={{ width: '10rem', padding: '2px' }} className='m-1'>
      <NavLink to={to}>
        <center>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            {children}
          </svg>
          <h4 className='m-1' style={{fontFamily:'Open Sans', color:'black', fontSize:'16px' }}>{title}</h4>
        </center>
      </NavLink>
    </Card>
  );
};

export default CardIcon;