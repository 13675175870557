import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Col, Container, Row, ProgressBar } from "react-bootstrap";
import Question from "./Question";
import { setStatus } from "../../store/actions/courseActions";
import { fetchUser } from '../../store/actions/usersActions';
import certificate from './certificate.jpg';
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import FontFaceObserver from "fontfaceobserver";

interface Auth {
  user: {
    uid: string;
  };
}

interface CourseStatus {
  completed: boolean;
  score: Record<string, string>;
  dateCompleted?: string;
  validThrough?: string;
  currentModule?: number;
}

interface Course {
  list: Record<string, {
    modules: {
      title: string;
      questions: any[];
    }[];
  }>;
  status: CourseStatus;
}

interface AppState {
  auth: Auth;
  courses: any;
  users: any;
  groups: any;
}

interface StateProps {
  auth: Auth;
  courses: any;
  user: any;
  groups: any;
}

interface OwnProps {
  increaseCountWithScore: (score: number) => void;
  count: number;
}

interface DispatchProps {
  setStatusProp: (uid: string, id: string, status: CourseStatus) => void;
  fetchUserProp: (uid: string) => void;
}

type Props = StateProps & OwnProps & DispatchProps;

const QuizPage: React.FC<Props> = ({ auth, courses, count, fetchUserProp, setStatusProp, user, groups, increaseCountWithScore }) => {
  const { id } = useParams<{ id: string }>();
  const { uid } = auth.user;
  const [name, setName] = useState('');
  const [finishDate, setFinishDate] = useState('');
  const [expiry, setExpiry] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [score, setScore] = useState(courses.status.completed ? parseFloat(courses.status.score['11']) : 0);
  const title = (id && courses.list[id]?.modules[count]?.title) ?? 'Unknown Title';
  const date = new Date();

  useEffect(() => {
    fetchUserProp(uid);
  }, [uid, fetchUserProp]);

  useEffect(() => {
    
    if (user) {
      setName(`${user.firstName} ${user.lastName}`);
      setFinishDate(courses.status.dateCompleted ?? '');
      setExpiry(courses.status.validThrough ?? '');
    }
  }, [user, courses.status]);

  useEffect(() => {
    if (imgUrl) {
      const pdf = new jsPDF('landscape', 'pt', 'a4');
      pdf.addImage(imgUrl, "JPEG", 20, 20, 800, 550);
      pdf.save(`${user.firstName} ${user.lastName}_${courses.list[id || ''].name}.pdf`);
    }
  }, [imgUrl]);

  const generateImage = async (name: string, startDate: string, endDate: string): Promise<void> => {
    // Observe the font loading
    const font = new FontFaceObserver('Italianno');
    await font.load();

    const img = new Image();
    img.src = certificate;

    await new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = () => reject(new Error('Certificate image failed to load.'));
    });

    const logoSrc = await import(`../../assets/${groups.list[user.group].name.toLowerCase()}.png`);
    const logo = new Image();
    logo.src = logoSrc.default;

    await new Promise<void>((resolve, reject) => {
      logo.onload = () => resolve();
      logo.onerror = () => reject(new Error('Logo image failed to load.'));
    });

    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
      ctx.drawImage(img, 0, 0);

      const relativeHeight = 70;
      const relativeWidth = logo.width / logo.height * relativeHeight;
      ctx.drawImage(logo, img.width - relativeWidth - 85, img.height - relativeHeight - 45, relativeWidth, relativeHeight);

      // Apply the font only when FontFaceObserver confirms it is loaded
      ctx.font = '140px Italianno';
      ctx.fillStyle = 'darkblue';
      ctx.textAlign = 'center';
      ctx.fillText(name, canvas.width / 2, 615);

      ctx.font = '40px Arial';
      ctx.fillStyle = 'black';
      ctx.fillText(startDate, canvas.width / 4, 860);
      ctx.fillText(endDate, (canvas.width * 3) / 4, 860);
    }

    const dataUrl = canvas.toDataURL();
    setImgUrl(dataUrl);
  };  

  const handleShow = () => {
    generateImage(name, finishDate, expiry);
  };

  const finishCourse = (score: number) => {
    if (!id) {
      console.error("Course ID is undefined");
      return;
    }

    const finishDate = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear()}`;
    const expiry = `${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}-${date.getFullYear() + 1}`;
    if (!courses.status.completed) {
      setStatusProp(uid ?? '', id ?? '', {
        ...courses.status,
        currentModule: 11,
        completed: true,
        dateCompleted: finishDate,
        validThrough: expiry,
        score: {
          ...courses.status.score,
          11: score.toString()
        }
      });
    }
    generateImage(name, finishDate, expiry);
  };

  const quizList = (id && courses.list[id]?.modules[count]?.questions) ?? [];
  const [qno, setQno] = useState(courses.status.completed ? Object.keys(quizList).length : 0);

  const increaseScore = () => {
    setScore(score + (100 / Object.keys(quizList).length));
  };

  const nextQuestion = () => {
    setQno(qno + 1);
  };

  const retake = () => {
    setQno(0);
    setScore(0);
    if (count === 11) {
      setStatusProp(uid ?? '', id ?? '', {
        currentModule: 0,
        completed: false,
        score: {}
      });
    }
  };

  const calculateProgress = () => {
    return (qno * 100) / Object.keys(quizList).length;
  };

  return (
    <Container>
      {!(courses.status && courses.status.completed) && (
        <>
          <h1>{title} {qno < Object.keys(quizList).length ? qno + 1 : qno} / {Object.keys(quizList).length}</h1>
          <ProgressBar now={calculateProgress()} />
        </>
      )}
      <Col>
        <Row>
          {qno < Object.keys(quizList).length ? (
            <Question key={qno} question={quizList[qno]} increaseScore={increaseScore} nextQuestion={nextQuestion} />
          ) : score < 80 ? (
            <>
              <h3>Score: {score}% - Failed</h3>
              <Col xs="auto">
                <Button onClick={retake}>Retake</Button>
              </Col>
            </>
          ) : ''}
        </Row>
        <Row className="justify-content-center">
          {(count < 11 && score > 80 && qno === Object.keys(quizList).length) ? (
            <>
              <h3>Score: {score}% - Passed</h3>
              <Col xs='auto'>
                <Button className='m-1' variant="success" onClick={() => increaseCountWithScore(score)}>
                  Finish And Next
                </Button>
              </Col>
            </>
          ) : count === 11 && score > 80 && qno === Object.keys(quizList).length && !courses.status.completed ? (
            <>
              <h3>Score: {score}% - Passed</h3>
              <Col xs='auto'>
                <Button className='m-1' variant="success" onClick={() => finishCourse(score)}>
                  Finish
                </Button>
              </Col>
            </>
          ) : ''}
          {courses.status.completed && (
            <Col xs='auto'>
              <Button className='m-1' variant="success" onClick={handleShow}>
                Generate Certificate
              </Button>
            </Col>
          )}
        </Row>
        {imgUrl && <img src={imgUrl} style={{ width: '100%', height: '' }} />}
      </Col>
    </Container>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  auth: state.auth,
  courses: state.courses,
  user: state.users.user,
  groups: state.groups,
});

const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  setStatusProp: (uid: string, id: string, status: CourseStatus) => dispatch(setStatus({ uid, id, status })),
  fetchUserProp: (uid: string) => dispatch(fetchUser(uid)),
});

export default connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(QuizPage);